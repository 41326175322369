// Home.js
import React from 'react';
import NavBar from './Navbar';
import Footer from './Footer';
import logo from '../assets/pro-touch-logo.png';
import './Home.css'; // Import the CSS file
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHammer, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';

function Home() {
  return (
    <div className="container">
      <NavBar />

      {/* Header Section */}
      <header className="header-section">
        <div className="header-overlay"></div>
        <div className="header-content">
          <img src={logo} alt="Pro Touch Logo" className="logo" />
          <Link to="/contact" className="contact-button">
            Contact Us
          </Link>
        </div>
      </header>

      {/* About Section */}
      <section className="about-section">
        <h2>About Pro-Touch Contractors, Inc.</h2>
        <p>
          Pro-Touch Contractors, Inc. is a leading small commercial construction company based in the vibrant
          coastal city of Corpus Christi, Texas. With a solid commitment to excellence, quality workmanship,
          and customer satisfaction, we've earned a trusted reputation for delivering outstanding construction
          services tailored to the unique needs of businesses in the Corpus Christi area.
        </p>
        <p>
          At Pro-Touch Contractors, we believe in the power of craftsmanship and collaboration. Our mission is
          to create innovative, cost-effective, and sustainable construction solutions that bring our clients'
          visions to life, enhancing their business operations and leaving a lasting impact on the community.
        </p>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-item">
            <div className="service-icon">
              <FontAwesomeIcon icon={faBuilding} size="6x" />
            </div>
            <h3>Comprehensive Commercial Construction</h3>
            <p>
              Pro-Touch Contractors specializes in constructing a wide range of commercial properties, including
              offices, retail spaces, and industrial facilities. We manage all aspects of the construction process,
              ensuring your project is completed on time and within budget.
            </p>
          </div>

          <div className="service-item">
            <div className="service-icon">
              <FontAwesomeIcon icon={faHammer} size="6x" />
            </div>
            <h3>Transformative Renovations & Remodeling</h3>
            <p>
              Transform your existing commercial space into a modern, efficient, and visually appealing environment
              that mirrors your brand's identity. Our renovations and remodeling services are tailored to meet your
              specific needs.
            </p>
          </div>

          <div className="service-item">
            <div className="service-icon">
              <FontAwesomeIcon icon={faDraftingCompass} size="6x" />
            </div>
            <h3>Customized Tenant Improvements</h3>
            <p>
              We assist businesses in creating the perfect space for their unique needs, from minor interior adjustments
              to complete overhauls. Our tenant improvement services focus on delivering customized solutions that enhance
              your business operations.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
