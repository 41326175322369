import React from 'react';

function Footer() {
  const styles = {
    footer: {
      background: '#333',
      color: 'white',
      textAlign: 'center',
      padding: '15px',
      // position: 'fixed',
      // left: '0',
      // bottom: '0',
      // width: '100%',
    },
    footerText: {
      // margin: '0',
      fontSize: '14px',
    }
  };

  return (
    <div style={styles.footer}>
      <p style={styles.footerText}>© {new Date().getFullYear()} Pro Touch Inc. All rights reserved.</p>
      <p style={styles.footerText}>
        <a href="tel:361-510-7723" style={{ color: 'inherit', textDecoration: 'none' }}>
          Tel: 361-510-7723
        </a>
      </p>
    </div>
  );
}

export default Footer;
