import React from 'react';
import NavBar from './Navbar';
import Footer from './Footer';
import { useForm, ValidationError } from '@formspree/react';

function Contact() {
  const [state, handleSubmit] = useForm("xpwagvje");

  const styles = {
    container: {
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      backgroundColor: '#f9f9f9',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
    contentWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '80px',
      paddingLeft: '15px',
      paddingRight: '15px',
      boxSizing: 'border-box',
    },
    header: {
      textAlign: 'center',
      marginBottom: '30px',
      color: '#333',
      fontSize: '32px',
      paddingLeft: '15px',
      paddingRight: '15px',
      boxSizing: 'border-box',
    },
    paragraph: {
      textAlign: 'center',
      marginBottom: '20px',
      color: '#333',
      fontSize: '16px',
      paddingLeft: '15px',
      paddingRight: '15px',
      boxSizing: 'border-box',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '500px',
      backgroundColor: '#fff',
      padding: '40px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
    },
    input: {
      padding: '12px 15px',
      marginBottom: '20px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      outline: 'none',
      boxSizing: 'border-box',
    },
    textArea: {
      padding: '12px 15px',
      marginBottom: '20px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      resize: 'vertical',
      outline: 'none',
      boxSizing: 'border-box',
    },
    button: {
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      padding: '15px',
      fontSize: '18px',
      cursor: 'pointer',
      borderRadius: '4px',
      transition: 'background-color 0.3s ease',
      boxSizing: 'border-box',
    },
    buttonHover: {
      backgroundColor: '#218838',
    },
    message: {
      textAlign: 'center',
      marginTop: '20px',
      color: '#28a745',
      fontSize: '24px',
      paddingLeft: '15px',
      paddingRight: '15px',
      boxSizing: 'border-box',
    },
  };

  const [isButtonHovered, setIsButtonHovered] = React.useState(false);

  if (state.succeeded) {
    return (
      <div style={styles.container}>
        <NavBar textColor="black" />
        <div style={styles.contentWrapper}>
          <h2 style={styles.message}>Thank you for contacting us!</h2>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <NavBar textColor="black" />
      <div style={styles.contentWrapper}>
        <h2 style={styles.header}>Get in Touch</h2>
        <p style={styles.paragraph}>
          Give us a call at <a href="tel:361-510-7723" style={{ color: 'inherit', textDecoration: 'none' }}>361-510-7723</a> or fill out the form below!
        </p>
        <form style={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            style={styles.input}
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            style={styles.input}
            required
          />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            style={styles.textArea}
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button
            type="submit"
            style={
              isButtonHovered
                ? { ...styles.button, ...styles.buttonHover }
                : styles.button
            }
            disabled={state.submitting}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            Send Message
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
