import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar';
import Footer from './Footer';

function About() {
  return (
    <div style={{fontFamily: 'Arial, sans-serif'}}>
      <NavBar />
      <h1>Welcome to the About Page!</h1>
      <Link to="/">Go to Home Page</Link>
      <Footer />
    </div>
  );
}

export default About;