// NavBar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function NavBar({ textColor = 'white' }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    navbar: {
      background: 'transparent',
      color: textColor,
      padding: '10px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      zIndex: 1000,
    },
    navLinks: {
      listStyleType: 'none',
      display: isMobile ? (isOpen ? 'flex' : 'none') : 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
      width: isMobile ? '100%' : 'auto',
      position: isMobile ? 'absolute' : 'static',
      top: isMobile ? '48px' : 'auto',
      left: isMobile ? '0' : 'auto',
      right: isMobile ? '0' : 'auto',
      backgroundColor: isMobile ? '#888' : 'transparent',
      padding: isMobile ? '20px 0' : '0',
      marginTop: isMobile ? '10px' : '0',
    },
    navLinkItem: {
      padding: '10px',
      width: isMobile ? '100%' : 'auto',
      textAlign: 'center',
    },
    navLink: {
      color: textColor,
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    logo: {
      fontWeight: 'bold',
      paddingLeft: '20px',
      color: textColor, // Apply textColor to the logo
    },
    toggleButton: {
      display: isMobile ? 'block' : 'none',
      fontSize: '30px',
      background: 'none',
      border: 'none',
      color: textColor,
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.navbar}>
      <div style={styles.logo}>Pro Touch Inc.</div>
      <button onClick={() => setIsOpen(!isOpen)} style={styles.toggleButton}>
        {isOpen ? '✖' : '☰'}
      </button>
      <ul style={styles.navLinks}>
        <li style={styles.navLinkItem}>
          <Link to="/" style={styles.navLink} onClick={() => setIsOpen(false)}>
            Home
          </Link>
        </li>
        {/* Uncomment or add more links as needed */}
        {/* <li style={styles.navLinkItem}>
          <Link to="/about" style={styles.navLink} onClick={() => setIsOpen(false)}>
            About
          </Link>
        </li> */}
        <li style={styles.navLinkItem}>
          <Link
            to="/contact"
            style={{ ...styles.navLink, marginRight: isMobile ? '0' : '10px' }}
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
